<template>
    <page title="服务介绍" :hasBack="true" textAlign="center">
        <div class="service-introduce-page">
            <div class="content">
                <img class="bg" src="@/assets/images/serviceIntroduce/bg.png" alt="" />
                <div class="introduce">
                    <div class="header">
                        <div class="line"></div>
                        <div class="vertical"></div>
                        <div class="text">服务介绍</div>
                        <div class="vertical"></div>
                        <div class="line"></div>
                    </div>
                    <div class="des">
                        {{ introduceDes }}
                    </div>
                </div>
            </div>
            <div class="buy-msg">
                <div class="price-msg">
                    <span>¥</span>
                    <span class="price">{{ price.toFixed(2) }}</span>
                    <span>/每人/每学期</span>
                </div>
                <van-button square type="primary" @click="goBuy">立刻开通</van-button>
            </div>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { ref } from 'vue';
import { Button } from 'vant';
import { useRouter } from 'vue-router';

export default {
    name: 'ServiceIntroduce',
    components: {
        [Page.name]: Page,
        [Button.name]: Button,
    },
    setup() {
        const router = useRouter();
        const goBuy = () => {
            router.push({
                path: '/serviceBuy',
            });
        };
        const price = ref(200);
        const introduceDes = ref(
            '以人为本，有爱服务客户是我们服务的宗旨，专业、及时、\n' +
                '真诚是我们服务的方针， “一切为了用户体验！”   是我们\n' +
                '服务的目标。'
        );
        return {
            price,
            introduceDes,
            goBuy,
        };
    },
};
</script>
<style lang="less" scoped>
.service-introduce-page {
    height: 100%;
    padding-bottom: 105px;
    box-sizing: border-box;
    .content {
        height: 100%;
        background: #ffffff;
        border-radius: 5px;
        /*margin-bottom: 66px;*/
        .bg {
            width: 100vw;
            margin-left: -15px;
        }
    }
    .introduce {
        .header {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .line {
            width: 65px;
            height: 1px;
            background: #cccccc;
            transform: scale(1, 0.5);
        }
        .vertical {
            width: 1px;
            height: 11px;
            border: 1px solid #cccccc;
            transform: scale(0.5, 1);
        }
        .text {
            font-size: 12px;
            font-family: PingFang SC, serif;
            color: #808080;
            margin: 0 9px;
        }
        .des {
            margin-top: 40px;
            font-size: 12px;
            padding: 0 15px;
            font-family: PingFang SC, serif;
            line-height: 24px;
            color: #cccccc;
            text-align: justify;
        }
    }
    .buy-msg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 49px;
        background: #ffffff;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .price-msg {
        margin-left: 15px;

        display: flex;
        align-items: flex-end;
        span {
            font-size: 12px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #ff467f;
        }
        .price {
            font-size: 24px;
            line-height: 22px;
            color: #ff467f;
        }
    }
    /deep/ .van-button {
        height: 100%;
    }
}
</style>
